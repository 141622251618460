* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body{
  overflow: hidden;
}

.react-datepicker-wrapper{
  width: auto;
}

/* .sidebar {
  height: calc(100vh - 100px);
} */

.sidebar__container {
  width: 150px;
}

.sidebar__link {
  color: #6c757d;
  text-decoration: none;
  cursor: pointer;
}
.sidebar__link:hover {
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
}

.navbar__style {
  background-color: #e7ecef;
}

.routes {
  height: calc(100vh - 100px);
  overflow: scroll;
}

.routes::-webkit-scrollbar {
  display: none;
}

.card {
  height: 450px;
}

.card__users {
  cursor: pointer;
}

.card-img-top {
  height: 260px;
}

.preview {
  display: flex;
  margin: 30px 0 60px;
  padding-left: 30px;
}

.preview__card {
  margin-right: 30px;
  display: flex;
}

.preview__img {
  width: 10rem;
}

.btn__icon {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.btn__icon img {
  width: 100%;
}
